import React from "react";
import * as moment from "moment";
import {
  COMPANY_CONSTANTS,
  LOGO_SRC,
  LOGO_SRC_VLEESCENTRUM,
  REVIEW_QR_CODE,
  REVIEW_QR_CODE_VLEESCENTRUM,
} from "constants/CompanyConstants";
import { Trans, useTranslation } from "react-i18next";
import { DATE_FORMAT_DD_MM_YYYY_HH_mm } from "constants/DateConstant";
import { DELIVERY_OPTIONS } from "constants/DeliveryConstants";

const PackingSlip = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  const data = props.data;
  const filteredData = data.filter((order) => !order.containsOnlyGiftCards);
  return (
    <div ref={ref}>
      {filteredData.map((order, index) => {
        const { isVleescentrum } = order;
        const address = order.customer.address.delivery;
        const isFrozen = order.delivery.frozen;
        // Review text adds happy x-mas when month is december
        const DECEMBER_INDEX = 11;
        let reviewText = "leave_review_packing_slip";
        const isDecember = moment(order.delivery.date, "DD-MM-YYYY").month() === DECEMBER_INDEX;
        if (isVleescentrum) {
          if (isDecember) reviewText = "leave_review_packing_slip_christmas_vleescentrum";
          else reviewText = "leave_review_packing_slip_vleescentrum";
        } else if (isDecember) reviewText = "leave_review_packing_slip_christmas";
        return (
          <div key={index} style={styles.container} break className="pagebreak">
            {isFrozen && (
              <span
                style={{
                  display: "block",
                  position: "absolute",
                  top: 0,
                  left: "50%",
                  transform: "translateX(-50%)",
                  color: "#000",
                  fontSize: TEXT_SIZE,
                  fontWeight: 700,
                }}
              >
                {t("frozen_order")}
              </span>
            )}
            <span style={styles.pageNumber}>{index}</span>
            <div style={styles.topRow}>
              <div style={styles.leftSection}>
                <div style={styles.logoContainer}>
                  <div>
                    <img src={isVleescentrum ? LOGO_SRC_VLEESCENTRUM : LOGO_SRC} style={styles.logo} alt="logo" />
                  </div>
                </div>
                <span style={styles.pageTitle}>{t("packing_slip").toUpperCase()}</span>
                <span style={styles.clientInfoText}>
                  {address.firstName} {address.lastName}
                </span>
                <span style={styles.clientInfoText}>{address.companyName}</span>
                <span
                  style={{
                    display: "block",
                    fontSize: TEXT_SIZE,
                    color: TEXT_COLOR,
                  }}
                >
                  {address.street} {address.houseNumber}
                  {address.addition}
                </span>
                <span style={styles.clientInfoText}>
                  {address.zipCode}, {address.city}
                </span>
                <span style={styles.clientInfoText}>{order.customer.email}</span>
                <span style={styles.clientInfoText}>{order.customer.phoneNumber}</span>
                <span
                  style={{
                    display: "block",
                    fontWeight: "bold",
                    marginTop: 24,
                    color: TITLE_COLOR,
                    fontSize: TEXT_SIZE,
                  }}
                >
                  #{order.orderId}
                </span>
                <span
                  style={{
                    display: "block",
                    color: TEXT_COLOR,
                    fontSize: TEXT_SIZE,
                  }}
                >
                  {t("order_date")}: {moment.unix(order.createdAt).format(DATE_FORMAT_DD_MM_YYYY_HH_mm)}
                </span>
                <span
                  style={{
                    display: "block",
                    color: TEXT_COLOR,
                    fontSize: TEXT_SIZE,
                  }}
                >
                  {order.delivery.type === DELIVERY_OPTIONS.SEND ? t("shipping_date") : t("pickup_date")}:{" "}
                  {moment(order.delivery.date, "DD-MM-YYYY").format("dddd D MMMM YYYY")}
                </span>
                <span
                  style={{
                    display: "block",
                    color: TEXT_COLOR,
                    fontSize: TEXT_SIZE,
                  }}
                >
                  {t("order_notes")}: {!!order.orderNotes ? order.orderNotes : "-"}
                </span>
              </div>
              <div style={styles.rightSection}>
                <span style={styles.senderCompanyName}>
                  {isVleescentrum ? "Het Vleescentrum" : COMPANY_CONSTANTS.COMPANY_NAME}
                </span>
                <div style={{ display: "block" }}>
                  <span style={styles.senderInfoText}>{COMPANY_CONSTANTS.STREET_AND_NUMBER}</span>
                  <span style={styles.senderInfoText}>{COMPANY_CONSTANTS.ZIP_AND_CITY}</span>
                  <span style={styles.senderInfoText}>{COMPANY_CONSTANTS.COUNTRY}</span>
                </div>
                <div style={{ display: "block" }}>
                  <span style={styles.senderInfoText}>
                    {t("kvk_number")}: {COMPANY_CONSTANTS.KVK}
                  </span>
                  <span style={styles.senderInfoText}>
                    {t("vat_number")}: {COMPANY_CONSTANTS.VAT}
                  </span>
                </div>
              </div>
            </div>
            <div style={styles.bottomRow}>
              <div style={styles.divider} />
              <div style={styles.clientInfoRow}>
                <div style={styles.table}>
                  <div style={styles.tableRow}>
                    <div style={styles.tableColName}>
                      <span style={styles.tableCellHeader}>{t("name")}</span>
                    </div>
                    <div style={styles.tableColUnitPrice}>
                      <span style={styles.tableCellHeader}>{t("weight")}</span>
                    </div>
                    <div style={styles.tableColQty}>
                      <span style={styles.tableCellHeader}>{t("quantity")}</span>
                    </div>
                  </div>
                  {order.products.map((product, key) => {
                    const name = product.type ? product.name + " (" + product.type + ")" : product.name;
                    const weight = !!product.variation ? product.variation.weight + " " + t("gram_unit") : "-";

                    if (product.giftCard) return null;
                    return (
                      <div>
                        <div style={styles.tableDivider} />
                        <div style={styles.tableRow} key={key}>
                          <div style={styles.tableColName}>
                            <span style={styles.tableCell}>{name}</span>
                          </div>
                          <div style={styles.tableColUnitPrice}>
                            <span style={styles.tableCell}>
                              <span style={styles.tableCell}>{weight}</span>
                            </span>
                          </div>
                          <div style={styles.tableColQty}>
                            <span style={styles.tableCell}>{product.quantity}</span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            {!!REVIEW_QR_CODE && (
              <div style={styles.askReviewWidget}>
                <img src={isVleescentrum ? REVIEW_QR_CODE_VLEESCENTRUM : REVIEW_QR_CODE} style={styles.qrCode} />
                <span style={styles.reviewText}>{t(reviewText)}</span>
              </div>
            )}
            <div style={styles.extraInfo}>
              <Trans i18nKey={"packing_slip_extra_info"} />
            </div>
          </div>
        );
      })}
    </div>
  );
});

const TITLE_SIZE = 18;
const TITLE_MARGIN = 10;
const TEXT_SIZE = 16;
const TITLE_COLOR = "#283147";
const TEXT_COLOR = "#6b6b6b";

const styles = {
  container: {
    display: "block",
    position: "relative",
    flex: 1,
    padding: 30,
  },
  contentContainer: {
    display: "block",
    justifyContent: "center",
    alignItems: "center",
  },
  logoContainer: {
    display: "block",
    marginBottom: 10,
  },
  logo: {
    display: "block",
    maxWidth: 150,
    maxHeight: 250,
    aspectRatio: "contain",
  },
  topRow: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    flex: 1,
    marginRight: -15,
    marginLeft: -15,
    paddingTop: 12,
    paddingBottom: 12,
  },
  bottomRow: {
    flex: 3,
  },
  pageTitle: {
    display: "block",
    fontWeight: "bold",
    color: TITLE_COLOR,
    marginTop: TITLE_MARGIN,
    marginBottom: TITLE_MARGIN,
    fontSize: TITLE_SIZE,
  },
  leftSection: {
    position: "relative",
    width: "100%",
    flex: 1,
    maxWidth: "60%",
    paddingRight: 15,
    paddingLeft: 15,
  },
  rightSection: {
    position: "relative",
    width: "100%",
    flex: 1,
    maxWidth: "40%",
    paddingRight: 15,
    paddingLeft: 15,
    textAlign: "right",
  },
  senderCompanyName: {
    display: "block",
    fontWeight: 800,
    color: TITLE_COLOR,
    marginBottom: TITLE_MARGIN,
    fontSize: TITLE_SIZE,
  },
  senderInfoText: {
    display: "block",
    marginBottom: 4,
    color: TEXT_COLOR,
    fontSize: TEXT_SIZE,
    width: "100%",
  },
  clientInfoText: {
    display: "block",
    marginBottom: 2,
    fontSize: TEXT_SIZE,
    color: TEXT_COLOR,
  },
  right: {
    justifyContent: "flex-end",
  },
  clientInfoRow: {
    paddingTop: 12,
    paddingBottom: 12,
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    flex: 1,
    width: "100%",
  },
  divider: {
    display: "flex",
    borderTopWidth: 1,
    borderTopStyle: "solid",
    borderTopColor: "#c5c5c5",
  },
  tableDivider: {
    display: "flex",
    borderTopWidth: 1,
    borderTopStyle: "solid",
    borderTopColor: "#e3e3e3",
  },
  table: {
    display: "table",
    flex: 1,
    width: "100%",
  },
  tableRow: {
    display: "flex",
    margin: "auto",
    flexDirection: "row",
    flex: 1,
    marginTop: 8,
    width: "100%",
  },
  tableColName: {
    flex: 6,
    textAlign: "left",
  },
  tableColUnitPrice: {
    flex: 2,
    textAlign: "right",
  },
  tableColQty: {
    flex: 2,
    textAlign: "right",
  },
  tableCol: {
    flex: 1,
    width: "25%",
  },
  tableCellHeader: {
    margin: 5,
    fontSize: TEXT_SIZE + 1,
    fontWeight: 800,
    color: TITLE_COLOR,
  },
  tableCell: {
    margin: 5,
    fontSize: TEXT_SIZE,
    color: TEXT_COLOR,
  },
  pageNumber: {
    position: "absolute",
    left: 30,
    fontSize: 15,
    color: "#a1a1a1",
    display: "block",
  },
  askReviewWidget: {
    marginTop: 15,
    fontSize: 15,
    display: "flex",
    alignItems: "flex-end",
  },
  reviewText: {
    width: "85%",
    fontSize: 17,
    color: "#9e9e9e",
    fontWeight: 600,
    alignSelf: "center",
    textAlign: "center",
  },
  reviewQrCode: {
    width: "15%",
  },
  qrCode: {
    maxWidth: 100,
  },
  extraInfo: {
    whiteSpace: "pre-line",
    marginTop: 6,
  },
};

export default PackingSlip;
