export const COMPANY_CONSTANTS = {
  COMPANY_NAME: "Halalvlees Online",
  STREET_AND_NUMBER: "De Hoek 91A",
  ZIP_AND_CITY: "1601MR, Enkhuizen",
  COUNTRY: "Nederland",
  KVK: "72794380",
  VAT: "NL859239056B01",
  WEBSITE_URL: "halalvlees-online.nl",
  WEBSITE_NAME: "Halalvlees Online",
};
export const VAT_COMPANY_NAME = "Vleescentrum Enkhuizen";
export const LOGO_SRC = "/img/logo.png";
export const LOGO_SRC_VLEESCENTRUM = "/img/logo-vleescentrum.webp";
export const REVIEW_QR_CODE = "/img/qr-code/review.png";
export const REVIEW_QR_CODE_VLEESCENTRUM = "/img/qr-code/review-vleescentrum.png";
