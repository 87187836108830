import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import SideNav from "components/layout-components/SideNav";
import TopNav from "components/layout-components/TopNav";
import Loading from "components/shared-components/Loading";
import MobileNav from "components/layout-components/MobileNav";
import HeaderNav from "components/layout-components/HeaderNav";
import PageHeader from "components/layout-components/PageHeader";
import Footer from "components/layout-components/Footer";
import AppViews from "views/app-views";
import { Grid, Layout } from "antd";

import navigationConfig from "configs/NavigationConfig";
import { NAV_TYPE_SIDE, NAV_TYPE_TOP, SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH } from "constants/ThemeConstant";
import utils from "utils";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { useFirestoreConnect } from "react-redux-firebase";
import {
  FIRESTORE_BANNERS_TABLE,
  FIRESTORE_CATEGORIES_TABLE,
  FIRESTORE_DISCOUNT_CODES_TABLE,
  FIRESTORE_FEATURED_PRODUCTS_TABLE,
  FIRESTORE_LOYALTY_CLAIMS_TABLE,
  FIRESTORE_LOYALTY_REWARDS_TABLE,
  FIRESTORE_LOYALTY_USERS_TABLE,
  FIRESTORE_ORDERS_TABLE,
  FIRESTORE_PRODUCTS_TABLE,
  FIRESTORE_PROMOTIONS_TABLE,
  FIRESTORE_USER_FEEDBACK_TABLE,
  FIRESTORE_USERS_TABLE,
} from "../../constants/FirestoreConstant";
import moment from "moment";
import _ from "lodash";

const { Content } = Layout;
const { useBreakpoint } = Grid;

export const AppLayout = ({ navCollapsed, navType, location, direction }) => {
  const queryModifications = useSelector((state) => state.queries);
  const initialQueries = [
    { collection: FIRESTORE_PRODUCTS_TABLE },
    { collection: FIRESTORE_PROMOTIONS_TABLE },
    { collection: FIRESTORE_DISCOUNT_CODES_TABLE },
    {
      collection: FIRESTORE_USERS_TABLE,
      where: ["role", "==", "webshopUser"],
      storeAs: "webshopUsers",
    },
    { collection: FIRESTORE_LOYALTY_USERS_TABLE },
    { collection: FIRESTORE_LOYALTY_REWARDS_TABLE },
    { collection: FIRESTORE_LOYALTY_CLAIMS_TABLE },
    { collection: FIRESTORE_FEATURED_PRODUCTS_TABLE },
    { collection: FIRESTORE_BANNERS_TABLE },
    { collection: FIRESTORE_CATEGORIES_TABLE, orderBy: "name" },
    { collection: FIRESTORE_USER_FEEDBACK_TABLE, orderBy: ["createdAt", "desc"] },
  ];
  const [allQueries, setAllQueries] = useState(initialQueries);

  // Fetch all collections from Firestore and put in redux store
  useFirestoreConnect(allQueries);
  const currentRouteInfo = utils.getRouteInfo(navigationConfig, location.pathname);
  const screens = utils.getBreakPoint(useBreakpoint());
  const isMobile = !screens.includes("lg");
  const isNavSide = navType === NAV_TYPE_SIDE;
  const isNavTop = navType === NAV_TYPE_TOP;
  const getLayoutGutter = () => {
    if (isNavTop || isMobile) {
      return 0;
    }
    return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH;
  };

  const { status } = useThemeSwitcher();

  useEffect(() => {
    if (!!queryModifications) {
      const queryClone = _.clone(allQueries);
      if (!!queryModifications.ordersMaxDaysAgo) {
        const orderDaysAgoTimestamp = moment().subtract(queryModifications.ordersMaxDaysAgo, "days").unix();
        queryClone.push({
          collection: FIRESTORE_ORDERS_TABLE,
          where: ["delivery.dateTimestamp", ">=", orderDaysAgoTimestamp],
        });
        setAllQueries(queryClone);
      } else {
        _.remove(queryClone, (item) => item.collection === FIRESTORE_ORDERS_TABLE);
        queryClone.push({
          collection: FIRESTORE_ORDERS_TABLE,
        });
        setAllQueries(queryClone);
      }
    }
  }, [queryModifications]);

  if (status === "loading") {
    return <Loading cover="page" />;
  }

  return (
    <Layout>
      <HeaderNav isMobile={isMobile} />
      {isNavTop && !isMobile ? <TopNav routeInfo={currentRouteInfo} /> : null}
      <Layout className="app-container">
        {isNavSide && !isMobile ? <SideNav routeInfo={currentRouteInfo} /> : null}
        <Layout className="app-layout" style={{ paddingLeft: 0, minHeight: "100vh" }}>
          <div className={`app-content ${isNavTop ? "layout-top-nav" : ""}`}>
            <PageHeader display={currentRouteInfo?.breadcrumb} title={currentRouteInfo?.title} />
            <Content>
              <AppViews />
            </Content>
          </div>
          <Footer />
        </Layout>
      </Layout>
      {isMobile && <MobileNav />}
    </Layout>
  );
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType, locale } = theme;
  return { navCollapsed, navType, locale };
};

export default connect(mapStateToProps)(React.memo(AppLayout));
