import { Descriptions, Table } from "antd";
import moment from "moment";
import {
  DATE_FORMAT_DAY_WITH_DATE,
  DATE_FORMAT_DD_MM_YYYY,
  DATE_FORMAT_DD_MM_YYYY_HH_mm,
} from "../../../constants/DateConstant";
import { CURRENCY, DELIVERY_OPTIONS } from "../../../constants/DeliveryConstants";
import Text from "antd/lib/typography/Text";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { formatPrice, getPaymentStatus } from "../../../functions/formatters";

const OrderListTable = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  return (
    <div ref={ref}>
      {props.data.map((order, index) => {
        return (
          <div style={{ pageBreakBefore: "always", padding: 30 }} key={index}>
            <Descriptions bordered title={<div className="flex-row">{t("order.order")}</div>} size="small" column={1}>
              <Descriptions.Item label={t("order.order_id")}>{order.orderId}</Descriptions.Item>
              <Descriptions.Item label={t("order_date")}>
                {moment.unix(order.createdAt).format(DATE_FORMAT_DD_MM_YYYY_HH_mm)}
              </Descriptions.Item>
              <Descriptions.Item label={t("name")}>
                {order.customer.firstName} {order.customer.lastName}
              </Descriptions.Item>
              <Descriptions.Item label={t("email")}>{order.customer.email}</Descriptions.Item>
              <Descriptions.Item label={t("phone")}>{order.customer.phoneNumber}</Descriptions.Item>
              <Descriptions.Item label={t("delivery_address")}>
                {order.customer.address.delivery.firstName} {order.customer.address.delivery.lastName} <br />
                {order.customer.address.delivery.companyName} {order.customer.address.delivery.companyName && <br />}
                {order.customer.address.delivery.street} {order.customer.address.delivery.houseNumber}{" "}
                {order.customer.address.delivery.addition}
                <br />
                {order.customer.address.delivery.zipCode}, {order.customer.address.delivery.city}
              </Descriptions.Item>
              {!!order.customer.address.invoice && (
                <Descriptions.Item label={t("invoice_address")}>
                  {order.customer.address.invoice.firstName} {order.customer.address.invoice.lastName} <br />
                  {order.customer.address.invoice.companyName} {order.customer.address.invoice.companyName && <br />}
                  {order.customer.address.invoice.street} {order.customer.address.invoice.houseNumber}{" "}
                  {order.customer.address.invoice.addition}
                  <br />
                  {order.customer.address.invoice.zipCode}, {order.customer.address.invoice.city}
                </Descriptions.Item>
              )}
              <Descriptions.Item label={t("shipping_method")}>
                {props.formatShippingMethod(order.delivery.type)}
              </Descriptions.Item>
              <Descriptions.Item label={t("pickup_delivery_date")}>
                {moment(order.delivery.date, DATE_FORMAT_DD_MM_YYYY).format(DATE_FORMAT_DAY_WITH_DATE)}
              </Descriptions.Item>
              <Descriptions.Item label={t("order_notes")}>
                {!!order.orderNotes ? order.orderNotes : "-"}
              </Descriptions.Item>
              <Descriptions.Item label={t("status")}>{getPaymentStatus(order.orderStatus)}</Descriptions.Item>
              <Descriptions.Item label={t("delivery_frozen")}>
                {order.delivery.frozen ? t("yes") : t("no")}
              </Descriptions.Item>
            </Descriptions>
            <div className="table-responsive pt-5">
              <h4>{t("products")}</h4>
              <Table
                columns={props.orderDetailsColumns}
                dataSource={order.products}
                pagination={false}
                rowKey="variantId"
                className="w-100"
                bordered={true}
                summary={(pageData) => {
                  let totalAmount = 0;
                  let totalDiscount = 0;

                  pageData.forEach(({ quantity, discount }) => {
                    totalAmount += quantity;
                    totalDiscount += discount * quantity;
                  });
                  return (
                    !!order && (
                      <>
                        {order.delivery.type === DELIVERY_OPTIONS.SEND && (
                          <Table.Summary.Row>
                            <Table.Summary.Cell>
                              <Text>{t("shipping")}</Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell />
                            <Table.Summary.Cell />
                            <Table.Summary.Cell />
                            <Table.Summary.Cell />
                            <Table.Summary.Cell align="right">
                              <Text>
                                {order.delivery.type === DELIVERY_OPTIONS.SEND
                                  ? "€" + formatPrice(order.delivery.cost)
                                  : "-"}
                              </Text>
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                        )}
                        {!!order.discount && (
                          <Table.Summary.Row>
                            <Table.Summary.Cell>
                              <Text>
                                {!!order.discount.percentage ? (
                                  <Trans
                                    i18nKey="cart_discount_percentage"
                                    values={{
                                      discountPercentage: order.discount.percentage,
                                      discountCode: order.discount.code,
                                    }}
                                  />
                                ) : (
                                  <Trans
                                    i18nKey="cart_discount_amount"
                                    values={{
                                      discountAmount: order.discount.amount,
                                      discountCode: order.discount.code,
                                    }}
                                  />
                                )}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell />
                            <Table.Summary.Cell />
                            <Table.Summary.Cell />
                            <Table.Summary.Cell />
                            <Table.Summary.Cell align="right">
                              <Text>- {CURRENCY + formatPrice(order.discount.totalDiscount)}</Text>
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                        )}
                        <Table.Summary.Row>
                          <Table.Summary.Cell>
                            <Text strong={true}>{t("total")}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="center" />
                          <Table.Summary.Cell align="center" />
                          <Table.Summary.Cell align="center">
                            <Text strong={true}>{totalAmount}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="center">
                            <Text strong={true}>€{formatPrice(totalDiscount)}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="right">
                            <Text strong={true}>€{formatPrice(order.totalPrice)}</Text>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </>
                    )
                  );
                }}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
});

export default OrderListTable;
