import axios from "axios";

export const sendShipmentToTrunkrs = async function (data) {
  // Send single shipment to Trunkrs and return response
  const response = await axios
    .post(process.env.REACT_APP_TRUNKRS_API_URL + "/shipments", data, {
      headers: {
        "x-api-key": `${process.env.REACT_APP_TRUNKRS_API_KEY}`,
      },
    })
    .catch((err) => {
      console.log(err);
      throw new Error(err.response.data.reason);
    });
  return response.data.data[0].trunkrsNr;
};

export const getAllTrunkrsShipments = async function () {
  // Get all shipments from Trunkrs and return response
  return await axios
    .get(process.env.REACT_APP_TRUNKRS_API_URL + "/shipments", {
      headers: {
        "x-api-key": `${process.env.REACT_APP_TRUNKRS_API_KEY}`,
      },
    })
    .catch((err) => {
      console.log(err);
      throw new Error(err.response.data.reason);
    });
};
