import React from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { Document, Page, Font, StyleSheet, View, Text } from "@react-pdf/renderer";

const PickListTable = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  const data = _.orderBy(props.data, ["category", "name", "weight"], ["asc", "asc", "asc"]);
  let categories = _.uniq(_.map(data, "category"));

  const getCategoryRow = (obj) => {
    // Filter the current category out of the categories to prevent it
    // from coming up twice in the table
    categories = categories.filter((item) => item !== obj.category);
    return (
      <View>
        <View style={styles.categoryRow}>
          <View style={styles.tableCellBold}>
            <Text style={styles.tableCellBold}>{obj.category}</Text>
          </View>
        </View>
        <View style={styles.tableDivider} />
      </View>
    );
  };
  return (
    <div ref={ref} style={{ padding: 30 }}>
      <Document>
        <Page size="A4">
          <View>
            <h2>{t("order.pick_list")}</h2>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableColName}>
                  <Text style={styles.tableCellHeader}>{t("name")}</Text>
                </View>
                <View style={styles.tableColDetails}>
                  <Text style={styles.tableCellHeader}>{t("details")}</Text>
                </View>
                <View style={styles.tableColQuantity}>
                  <Text style={styles.tableCellHeader}>{t("quantity")}</Text>
                </View>
              </View>
              <View style={styles.tableDivider} />
              {data.length > 0 &&
                data.map((obj, index) => {
                  return (
                    <View key={index}>
                      <View style={styles.tableDivider} />
                      {categories.includes(obj.category) && getCategoryRow(obj)}
                      <View style={styles.tableRow} key={index}>
                        <View style={styles.tableColName}>
                          <Text style={styles.tableCell}>{obj.name}</Text>
                        </View>
                        <View style={styles.tableColDetails}>
                          <Text style={styles.tableCell}>
                            {obj.weight
                              ? obj.weight + " " + t("gram") + ": " + obj.amountPerOrder.join(" - ")
                              : obj.amountPerOrder.join(" - ")}
                          </Text>
                        </View>
                        <View style={styles.tableColQuantity}>
                          <Text style={styles.tableCell}>{obj.quantity}</Text>
                        </View>
                      </View>
                    </View>
                  );
                })}
            </View>
          </View>
        </Page>
      </Document>
    </div>
  );
});

const TEXT_SIZE = 14;
const TITLE_COLOR = "#283147";
const TEXT_COLOR = "#6b6b6b";

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#FFF",
  },
  tableDivider: {
    display: "flex",
    borderTopWidth: 1,
    borderTopStyle: "solid",
    borderTopColor: "#e3e3e3",
  },
  table: {
    display: "table",
    flex: 1,
    width: "100%",
  },
  tableRow: {
    display: "flex",
    margin: "auto",
    flexDirection: "row",
    flex: 1,
    marginTop: 8,
    width: "100%",
  },
  categoryRow: {
    display: "flex",
    margin: "auto",
    flexDirection: "row",
    flex: 1,
    marginTop: 8,
    backgroundColor: "#ffffff",
    width: "100%",
  },
  tableColName: {
    flex: 3,
    textAlign: "left",
  },
  tableColDetails: {
    flex: 6,
    textAlign: "center",
  },
  tableColQuantity: {
    flex: 1,
    textAlign: "right",
  },
  tableCellHeader: {
    margin: 5,
    fontSize: TEXT_SIZE + 1,
    fontWeight: 800,
    color: TITLE_COLOR,
    fontFamily: "Open Sans",
  },
  tableCell: {
    margin: 5,
    fontSize: TEXT_SIZE,
    color: TEXT_COLOR,
    fontFamily: "Open Sans",
  },
  tableCellBold: {
    margin: 5,
    fontWeight: 800,
    fontSize: TEXT_SIZE + 2,
    color: TITLE_COLOR,
    fontFamily: "Open Sans",
  },
});

export default PickListTable;
